import { ref } from 'vue'

function useWebsocket (
  typeWS,
  code,
  user,
  language = 'es-ES',
  vocabulary = '',
  trans = []
) {
  //const baseURL = process.env.VUE_APP_BASE_URL
  const baseURL = 'copla.io'
  //const baseURL = 'saar2.gq'
  let connected = ref(false)
  let message = ref('')
  let error = ref('')
  let url = 'wss://live.' + baseURL + '/' + typeWS
  let ws = null
  let last = ref('')
  let lang = language
  let newCode = code
  let voc = vocabulary

  function setLanguage (newLanguage) {
    lang = newLanguage
  }

  function setVocabulary (newVocabulary) {
    voc = newVocabulary
  }

  function setCode (code) {
    newCode = code
  }

  function connect () {
    ws = new WebSocket(url)
    ws.onopen = () => {
      console.log('Conexion ' + newCode + ' de tipo ' + typeWS + ' abierta')
      if (typeWS == 'listen') {
        connected.value = true
      }
      error = false
      let configMsg = config()
      send(configMsg)
    }
    ws.onerror = e => {
      console.log('Conexion ' + newCode + ' error')
      connected.value = false
      error = e
      ws.close()
    }
    ws.onclose = () => {
      console.log('Conexion ' + newCode + ' cerrada')
      connected.value = false
    }
    ws.onmessage = e => {
      console.log(e)
      if (JSON.parse(e.data).error) {
        error = e.data.data
        ws.close()
      } else {
        if (e.data.error) {
          error = e.data.data
          ws.close()
        } else {
          if (typeWS == 'listen') {
            message.value = parseText(e.data)
            console.log('Mensaje: ' + message.value)
          } else {
            connected.value = true
          }
        }
      }
    }
  }

  function disconnect () {
    ws.close()
  }

  function send (message) {
    ws.send(message)
  }

  function isConnected () {
    return connected.value
  }

  function config () {
    let data = null
    switch (typeWS) {
      case 'listen':
        data = {
          type: 'listen',
          data: {
            code: newCode
          }
        }
        break
      case 'transcription':
        data = {
          type: 'config',
          data: {
            user: user,
            code: newCode,
            language: lang,
            bandwidth: 16000,
            vocabulary: voc
          }
        }
        break
      case 'translation':
        data = {
          type: 'config',
          data: {
            user: user,
            code: newCode,
            language: lang,
            bandwidth: 16000,
            vocabulary: voc,
            translated: trans
          }
        }
    }
    return JSON.stringify(data)
  }

  function parseText (data) {
    let dataJSON = JSON.parse(data)
    let type = dataJSON.type
    let text = dataJSON.data.text
    let firstCapitalize = text.charAt(0).toUpperCase() + text.slice(1)
    let msg = ''
    switch (type) {
      case 'recognition':
        if (firstCapitalize.trim() != '') {
          if (dataJSON.data.type == 'partial') {
            if (last.value != '') {
              msg = last.value + '\r\n' + firstCapitalize
            } else {
              msg = firstCapitalize
            }
          } else {
            if (last.value != '') {
              last.value += '\r\n' + firstCapitalize
              msg = last.value
            } else {
              last.value += firstCapitalize
              msg = last.value
            }
          }
        } else {
          msg = last.value
        }
        break
    }
    return msg
  }

  return {
    connected,
    message,
    last,
    error,
    connect,
    disconnect,
    send,
    isConnected,
    lang,
    code,
    ws,
    typeWS,
    user,
    setLanguage,
    setCode,
    setVocabulary,
  }
}

export default useWebsocket
